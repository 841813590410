import React from "react"
import styled from "styled-components"

import { graphql } from "gatsby"

import { Layout, Seo } from "components/common"

import { useMeta } from "utils"

import Nav from "components/navigation/Nav"
import MobileNav from "src/components/navigation/mobile/MobileNav"

import Footer from "src/components/navigation/Footer"
import ProductHero from "src/sections/product/ProductHero"
import ProductCards from "src/sections/product/ProductCards"

import BottomNewsletterCta from "src/components/common/shared/BottomNewsletterCta"
// import DeepwatersManifesto from "src/sections/product/DeepwatersManifesto"

const ProductTemplatePage = ({ data: { product, bottom_cta,matrix_cta } }) => {
  const { shortTitle, shortSubtitle, productCards, media } = product

  const meta = useMeta({
    metaTitle: shortTitle,
    metaDescription: shortSubtitle,
    metaImage: media.desktop,
  })

  return (
    <Layout>
      <Seo {...meta} />

      <Nav />
      <MobileNav />

      <StyledProductTemplatePage>
        <ProductHero {...product} matrix_cta={matrix_cta} />

        <ProductCards productCards={productCards} />

        {/* <div className="manifesto">
          <DeepwatersManifesto />
        </div> */}

        <BottomNewsletterCta {...bottom_cta} />
      </StyledProductTemplatePage>
      <Footer />
    </Layout>
  )
}

const StyledProductTemplatePage = styled.div`
  /* --section-max-width: 1080px; */

  position: relative;
  z-index: 1;
  min-height: calc(var(--vh, 1vh) * 100);

  .manifesto {
    --section-padding-top: var(--sp-212);
    --section-mobile-padding-top: var(--sp-120);
  }
`

export default ProductTemplatePage

export const query = graphql`
  query ProductSlug($slug: String!) {
    product: contentfulProduct(slug: { eq: $slug }) {
      ...Product
    }
    bottom_cta: contentfulSection(sectionId: { eq: "bottom_newsletter_cta" }) {
      ...Section
    }
      matrix_cta: contentfulSection(sectionId: { eq: "comparative_matrix" }) {
          ...Section
      }
  }
`
