// map over productCards and render ProductCard component
import React from "react"
import styled from "styled-components"
import map from "lodash/map"

import ProductCard from "src/components/product/ProductCard.js"
import { HContainer } from "src/components/common"

const ProductCards = ({ productCards }) => {
  const renderGrid = () => (
    <ul className="product-grid">
      {map(productCards, (productCard) => {
        return <ProductCard key={productCard.id} {...productCard} />
      })}
    </ul>
  )

  return (
    <StyledProductCards>
      <HContainer>{renderGrid()}</HContainer>
    </StyledProductCards>
  )
}

const StyledProductCards = styled.ul`
  .product-grid {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: var(--sp-32);
    margin-right: auto;
  }
`

export default ProductCards
