import React from "react"
import styled from "styled-components"
import { richTextBody, gradientText, device } from "src/utils"
import { Card2, Media, TextGroup } from "../common"

const ProductCard = ({ title, body, media, id }) => {
  return (
    <StyledProductCard id={id}>
      <Card2>
        <TextGroup title={title} titleAs="h3" subtitle={body} />
        {media && <Media className="productCard-media" media={media} />}
      </Card2>
    </StyledProductCard>
  )
}

const StyledProductCard = styled.li`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: var(--sp-24);

  scroll-margin-top: 124px; // sets scrollintoview offest

  @media ${device.laptop} {
    scroll-margin-top: 32px; // sets scrollintoview offest
    grid-template-columns: minmax(auto, 720px) auto;
    grid-gap: var(--sp-48);
  }

  .tgWrap {
    ${richTextBody};
    --tg-grid-gap: 0;

    .title {
      margin-bottom: var(--sp-12);
    }

    h5 {
      /* 14px */
      font-size: 0.875rem;
      line-height: 1;
      letter-spacing: 0.12em;
      margin-bottom: var(--sp-8);
      font-weight: 600 !important;

      justify-self: flex-start;

      ${gradientText(
        "linear-gradient(97.86deg, #D556E0 -13.44%, #E86584 108.56%)"
      )};
    }

    p {
      /* 14px */
      font-size: 0.875rem;
      line-height: 22px;

      &:last-child {
        margin-bottom: 0;
      }
    }

    ul,
    ol {
      color: var(--body);
      li {
        color: var(--body);
      }
    }
  }

  .productCard-media {
    img {
      object-fit: contain !important;
    }
  }
`

export default ProductCard
