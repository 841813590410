import React from "react"
import { Grid, HContainer, HSection } from "src/components/common"
import styled from "styled-components"

import { device } from "utils"

import TestNetCtaGroup from "src/components/cta/TestNetCtaGroup.js"
import ComparativeMatrix from "components/matrix/comparativeMatrix"

const ProductHero = ({
  title,
  slug,
  subtitle,
  media,
  ctas,
  productCards,
  matrix_cta,
}) => {
  const handleScrollTo = (id) => {
    // scroll to the product cards using id

    const element = document.getElementById(id)
    element.scrollIntoView({ behavior: "smooth", block: "start" })
  }

  const renderTableOfContents = () => (
    <>
      <h4>Table of Contents</h4>
      <Grid
        list={productCards}
        component={({ title, id }) => (
          <button onClick={() => handleScrollTo(id)}>{title}</button>
        )}
      />
    </>
  )

  return (
    <StyledProductHero>
      <HSection
        className="productHero"
        title={title}
        titleAs="h1"
        subtitle={subtitle}
        media={media}
        cta={() => <TestNetCtaGroup ctas={ctas} />}
      />
      {slug === "problems" && <ComparativeMatrix {...matrix_cta} />}
      <HContainer className="tableContainer">
        <TableOfContents>{renderTableOfContents()}</TableOfContents>
      </HContainer>
    </StyledProductHero>
  )
}

const StyledProductHero = styled.div`
  .productHero {
    --section-padding-top: 60px;
    --section-mobile-padding-top: 20vh;
    --section-padding-bottom: 0;
    --section-mobile-padding-bottom: 0;
    --h-section-gap: var(--sp-80);
    --h-section-col: minmax(607px, 1fr) 1fr;

    .title {
      margin-bottom: var(--sp-16);
    }

    .subtitle {
      white-space: break-spaces;
    }

    .h_ctaContainer {
      --section-order: 3;
      --mobile-section-order: 3;
      margin-top: var(--sp-24);
    } // end of .v_ctaContainer

    .h_mediaContainer {
      order: 2;
      img {
        object-fit: contain !important;
      }
    }
  }
.half-side-container{
  padding: var(--sp-56) 0;
}
  .tableContainer {
    background: linear-gradient(180deg, #050e1b 0%, #061120 100%);

    padding: var(--sp-40) var(--sp-24);
    /* margin-bottom: var(--sp-40); */

    h4 {
      margin-bottom: var(--sp-16);
    }

    /* @media ${device.laptop} {
      margin-bottom: var(--sp-80);
    } */
  }

  .h_customContainer {
  }
`

const TableOfContents = styled.div`
  ul {
    --grid-col: 1;
    --grid-gap: var(--sp-4);
    --grid-mobile-gap: var(--sp-4);
    display: inline-grid;

    button {
      display: inline-block;
      color: #7daaff;
      font-size: 1rem;
      font-weight: 600;
      text-align: left;
      cursor: pointer;
      transition: 0.2s opacity ease-in-out;

      &:hover {
        opacity: 0.8;
      }
    }
  }
`

export default ProductHero
